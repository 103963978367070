

import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router";
import "../Styles/RaceScreens.css";
import PreloadedImages from "../Modules/PreLoadedImages";
import FreeToteBoards from "./FreeToteBoards";
import { FreeContext } from "./FreeContext";
import { appConfig } from "../../appConfig";


const apiUrl = process.env.REACT_APP_API_BASE_URL;


function RaceScreens({ startImage, gateImage, trackImage }) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const navigate = useNavigate();
  const { race, setRace, selectedHorseIds, setSelectedHorseIds, selectedHorseNames, setSelectedHorseNames } =
    useContext(FreeContext);

  const [images, setImages] = useState([]);
  const [showTrack, setShowTrack] = useState(false);
  const [loading, setLoading] = useState(true);
  const [horseSpeeds, setHorseSpeeds] = useState([]);
  const [horseDurations, setHorseDurations] = useState([]);
  const [numHorses, setNumHorses] = useState(7);
  const minimumRaceDuration = 60;
  const raceTrackRef = useRef(null);
  

  const fetchRace = async () => {
    try {
      if (!id) {
        console.error("Invalid race ID");
        return;
      }
      const response = await axios.get(`${appConfig.backendUrl}/current_races/${id}`);
      setRace(response.data);
      const numHorses = response.data.num_horses;
      setNumHorses(numHorses);
    } catch (error) {
      console.error('Non-Betting Race Selected');
    }
  };

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`${appConfig.backendUrl}/images`);
        const allImages = response.data;
        const shuffledImages = shuffleArray(allImages);
        const selectedImages = shuffledImages.slice(0, numHorses);

        setImages(selectedImages);

        const initialSpeeds = selectedImages.map(() => { 
          const currentSpeed = Math.random() * 129 + 503;
          const maxChange = currentSpeed * 1.73;
          const previousSpeed = currentSpeed - Math.random() * maxChange;
          const newSpeed = Math.max(
            Math.min(currentSpeed, previousSpeed + maxChange),
            previousSpeed - maxChange
          );
          return newSpeed;
        });
        setHorseSpeeds(initialSpeeds);

        const randomDurations = selectedImages.map(() => {
          const randomDuration = Math.random() * 30 + 60;
          return randomDuration.toFixed(2);
        });
        setHorseDurations(randomDurations);

        const imagePromises = selectedImages.map((image) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
              console.log(`Image loaded successfully: ${appConfig.backendUrl}/images/${image.image_name}`);
              resolve();
            };
            img.onerror = (error) => {
              console.log(`Error loading image: ${appConfig.backendUrl}/images/${image.image_name}`, error);
              reject(error);
            };
            img.src = appConfig.backendUrl + "/images/" + image.image_name;
          });
        });

        await Promise.all(imagePromises);
      } catch (error) {
        console.log("Error during image preloading:", error);
      }

      setLoading(false);
    };

    fetchImages();

    setTimeout(() => {
      setShowTrack(true);
    }, 2000);

    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "";
    };
  }, [numHorses]);

  const shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  function getRandomValue(min, max) {
    const range = max - min;
    const variation = range * 1.90;
    const randomOffset = Math.random() * variation - variation / 2.7;
    return (Math.random() * range + min + randomOffset).toFixed(2);
  }

  return (
    <>
      <PreloadedImages horseImages={images}>
        {(startImage, gateImage, trackImage) => (
          <div className="race-screens-container">
            <figure className="track-container">
              <img
                className={`track-left ${showTrack ? "fade-out" : ""}`}
                src={appConfig.backendUrl + "/images/start.gif"}
                alt="Start"
              />
              {showTrack && (
                <img
                  className={`track-right ${showTrack ? "fade-in" : ""}`}
                  src={appConfig.backendUrl + "/images/track.gif"}
                  alt="Track"
                />
              )}
            </figure>

            <figure className="race-screen-race-track" ref={raceTrackRef}>
              {images.map((image, index) => {
                const randomOffset = Math.random() * 1.87;
                return (
                  <figure
                    key={image.id}
                    className="horse"
                    style={{
                      animationDuration: `${horseSpeeds[index]}s`,
                      animationDelay: `${randomOffset + index * 0.737}s`,
                      marginTop: `${52 + index * 2.5}vh`,
                      marginLeft: `${-12}vw`,
                      animationTimingFunction: `cubic-bezier(
                        ${getRandomValue(0.04, 0.073)},
                        ${getRandomValue(0.07, 0.93)},
                        ${getRandomValue(0.07, 0.77)},
                        ${getRandomValue(0.03, 0.99)})`
                    }}
                  >
                    <img
                      className="race-screen-horse-container"
                      src={appConfig.backendUrl + "/images/" + image.image_name}
                      alt={image.image_name}
                    />
                    <div
                      className="horse-number" // Display the horses number
                      style={{
                        position: 'absolute',
                        top: '40%',
                        left: '48%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: '#fff',
                        fontSize: '18px',
                        fontWeight: 'bold',
                      }}
                    >
                      {index + 1}
                    </div>
                  </figure>
                );
              })}
            </figure>
            <div className="freetoteboards-overlay">
              <FreeToteBoards selectedHorses={images.map((image, index) => ({
                number: index + 1,
                name: `Horse ${index + 1}`,
              }))} />
            </div>
          </div>
        )}
      </PreloadedImages>
    </>
  );
}

export default RaceScreens;



