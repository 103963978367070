// src/appConfig.js

// Import dotenv to load environment variables for the Node.js backend
// require('dotenv').config();

const apiUrl = process.env.REACT_APP_API_URL;  // Use React-specific environment variables


// Centralized configuration using environment variables with fallback defaults
export const appConfig = {
    // API base URL (for React frontend and Node.js backend)
    backendUrl: process.env.REACT_APP_API_BASE_URL || 'http://localhost:3005',

    // Database configuration with environment variables for Node.js backend
    db: {
        user: process.env.DB_USER || 'defaultUser',
        password: process.env.DB_PASSWORD || 'defaultPassword',
        database: process.env.DB_DATABASE || 'defaultDatabase',
        port: parseInt(process.env.DB_PORT, 10) || 5432,
        host: process.env.DB_HOST || 'localhost',
    },

    // Security secrets (for Node.js backend)
    jwtSecret: process.env.JWT_SECRET || 'defaultJwtSecret',
    sessionSecret: process.env.SESSION_SECRET || 'defaultSessionSecret',

    // Centralized paths for media files (for Node.js backend)
    paths: {
        images: process.env.IMAGES_PATH || 'b:/public/images',
        pics: process.env.PICS_PATH || 'b:/public/pics',
        documents: process.env.DOCS_PATH || 'b:/public/documents',
    }
};
