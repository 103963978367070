

// import React, { useEffect, useState } from 'react';

// const PreloadedImages = ({ children }) => {
//   const [imagesLoaded, setImagesLoaded] = useState(false);
//   const [startImage, setStartImage] = useState(null);
//   const [gateImage, setGateImage] = useState(null);
//   const [trackImage, setTrackImage] = useState(null);

//   useEffect(() => {
//     const preloadImages = async () => {
//       try {
//         const startImg = new Image();
//         startImg.onload = () => {
//           // console.log("Start image loaded successfully");
//           setStartImage(startImg);
//         };
//         startImg.onerror = (error) => {
//           console.log("Error loading start image:", error);
//         };
//         startImg.src = process.env.PUBLIC_URL + "images/start.gif";

//         const gateImg = new Image();
//         gateImg.onload = () => {
//           // console.log("Gate image loaded successfully");
//           setGateImage(gateImg);
//         };
//         gateImg.onerror = (error) => {
//           console.log("Error loading gate image:", error);
//         };
//         gateImg.src = process.env.PUBLIC_URL + "images/startGate.gif";

//         const trackImg = new Image();
//         trackImg.onload = () => {
//           // console.log("Track image loaded successfully");
//           setTrackImage(trackImg);
//         };
//         trackImg.onerror = (error) => {
//           console.log("Error loading track image:", error);
//         };
//         trackImg.src = process.env.PUBLIC_URL + "images/track.gif";
//       } catch (error) {
//         console.log("Error during image preloading:", error);
//       }
//     };

//     preloadImages();
//   }, []);

//   useEffect(() => {
//     if (startImage && gateImage && trackImage) {
//       setImagesLoaded(true);
//     }
//   }, [startImage, gateImage, trackImage]);    


//   return (
//     <div >
//       {!imagesLoaded ? (
//         <div style={{ textAlign: "center", maxWidth: "34%", zIndex:"5",  display: 'inline-block', }}>
//           <h2>First Loading images will take a few seconds ....</h2>
//           {/* You can customize the loading UI as needed */}
//         </div>
//       ) : (
//         <>
        
//           {startImage && gateImage && trackImage && children(startImage, gateImage, trackImage)}
//                  </>
//       )}

//     </div>
//   );
// };

// export default PreloadedImages;

import React, { useEffect, useState } from 'react';
import { appConfig } from '../../appConfig';  // Import the configuration

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const PreloadedImages = ({ children }) => {
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [startImage, setStartImage] = useState(null);
  const [gateImage, setGateImage] = useState(null);
  const [trackImage, setTrackImage] = useState(null);

  useEffect(() => {
    const preloadImages = async () => {
      try {
        // Using config to retrieve the backend URL
        const startImg = new Image();
        startImg.onload = () => setStartImage(startImg);
        startImg.onerror = (error) => console.log("Error loading start image:", error);
        startImg.src = `${appConfig.backendUrl}/images/start.gif`;  // Replaced process.env.REACT_APP_BACKEND_URL with config.backendUrl

        const gateImg = new Image();
        gateImg.onload = () => setGateImage(gateImg);
        gateImg.onerror = (error) => console.log("Error loading gate image:", error);
        gateImg.src = `${appConfig.backendUrl}/images/startGate.gif`;

        const trackImg = new Image();
        trackImg.onload = () => setTrackImage(trackImg);
        trackImg.onerror = (error) => console.log("Error loading track image:", error);
        trackImg.src = `${appConfig.backendUrl}/images/track.gif`;
      } catch (error) {
        console.log("Error during image preloading:", error);
      }
    };

    preloadImages();
  }, []);

  useEffect(() => {
    if (startImage && gateImage && trackImage) {
      setImagesLoaded(true);
    }
  }, [startImage, gateImage, trackImage]);

  return (
    <div>
      {!imagesLoaded ? (
        <div style={{ textAlign: "center", maxWidth: "34%", zIndex: "5", display: 'inline-block' }}>
          <h2>Loading images will take a few seconds...</h2>
        </div>
      ) : (
        children(startImage, gateImage, trackImage)
      )}
    </div>
  );
};

export default PreloadedImages;
